import React from "react";

const Altarconsole = () => {
  ////// console nav bar

  // nav bar ( just , current cluster)

  // GET request for platforms

  // GET request for STRUCTURES

  return (
    <div className="altar-console">
      <div className="altar-console-cloth">
        console nav bar
        <div>
          <div> zeus db data </div>
          <div>
            <div>platforms </div>

            <div>data sheets springing off platforms ( structures )</div>
          </div>

          {/* <div> light data </div>
          <div> temple grid integration </div> */}
        </div>
      </div>
    </div>
  );
};

export default Altarconsole;
